<template>
  <a-card :bordered="false">
    <a-row :gutter="24">
      <a-col :md="6" :sm="24">
        <a-input placeholder="名称" v-model="queryParam.name" />
      </a-col>
      <a-col :md="6" :sm="6">
        <a-button class="editable-add-btn" @click="query">查询</a-button>
        <a-button
          style="margin-left: 8px;"
          class="editable-add-btn"
          @click="insert"
          >新增</a-button
        >
      </a-col>
    </a-row>
    <a-table
      style="margin-top: 10px;"
      rowKey="id"
      bordered
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <template slot="operation" slot-scope="text, record">
        <a @click="updateEnable(record)">
          <span v-if="record.enabled">停用</span>
          <span v-else>启用</span>
        </a>
        <a style="margin-left: 8px;" @click="update(record)">修改</a>
        <a-popconfirm
          title="确定删除?"
          ok-text="确定"
          cancel-text="否"
          @confirm="removeCompany(record)"
        >
          <a style="margin-left: 8px; color: red">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      title="编辑"
      :width="640"
      :visible="edit.show"
      v-if="edit.show"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-select
        placeholder="快递名称"
        showSearch
        :filterOption="filterOption"
        :allowClear="true"
        v-model.trim="edit.name"
        class="top5"
        style="width: 100%;"
      >
        <a-select-option v-for="array in logisticsList" :key="array.vague">{{
          array.vague
        }}</a-select-option>
      </a-select>
      <!--      <a-input class="top5" placeholder="快递名称" v-model="edit.name" />-->
      <a-input-number
        class="top5"
        style="width: 100%;"
        :precision="2"
        placeholder="运费(对平台)"
        v-model="edit.price"
      />
      <a-input-number
        class="top5"
        style="width: 100%;"
        :precision="3"
        placeholder="运费(官方提成)"
        v-model="edit.adminPrice"
      />
      <a-input class="top5" placeholder="备注" v-model="edit.comment" />
    </a-modal>
  </a-card>
</template>

<script>
import { PageView } from "@/layouts";
import {
  addressLogisticsList,
  addressLogisticsUpdate,
  addressLogisticsInsert,
  addressLogisticsAll,
  removeCompanyApi
} from "@/api";
import { formatDate } from "@/utils/util";

export default {
  /** 管理员操作时候调用该组件会传 admin uid仓库id */
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      edit: { show: false },
      queryParam: {},
      logisticsList: [],
      columns: [
        {
          title: "#",
          scopedSlots: { customRender: "serial" },
          width: "5%"
        },
        {
          title: "快递名称",
          dataIndex: "name",
          width: "10%"
        },
        {
          title: "运费(对平台)",
          dataIndex: "price",
          width: "10%"
        },
        {
          title: "运费(官方提成)",
          dataIndex: "adminPrice",
          width: "10%"
        },
        {
          title: "备注",
          dataIndex: "comment",
          width: "13%"
        },
        {
          title: "停用",
          dataIndex: "enabled",
          width: "10%"
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate,
          width: "15%"
        },
        {
          title: "修改时间",
          dataIndex: "updateTime",
          customRender: formatDate,
          width: "15%"
        },
        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: []
    };
  },
  created() {
    addressLogisticsAll().then(res => {
      this.logisticsList = res.data;
    });
    this.query();
  },
  methods: {
    query() {
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      };
      addressLogisticsList({ ...this.queryParam, ...extra }).then(res => {
        this.dataSource = res.data;
      });
    },
    insert() {
      this.edit = { show: true };
    },
    update(record) {
      record.show = true;
      this.edit = JSON.parse(JSON.stringify(record));
    },
    updateEnable(record) {
      record.enabled = !record.enabled;
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      };
      addressLogisticsUpdate({ ...record, ...extra }).then(res => {
        this.handleRes(res);
      });
    },
    handleCancel() {
      this.edit = { show: false };
    },
    handleOk() {
      const edit = this.edit;
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      };
      if (edit.id) {
        addressLogisticsUpdate({ ...edit, ...extra }).then(res => {
          this.handleRes(res);
        });
      } else {
        addressLogisticsInsert({ ...edit, ...extra }).then(res => {
          this.handleRes(res);
        });
      }
    },
    handleRes(res) {
      if (res.success) {
        this.$message.success("成功");
        this.query();
        this.edit = { show: false };
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeCompany(record) {
      const res = await removeCompanyApi(record.id);
      console.log("[res] ---> ", res);
      if (res.code === 0) {
        this.$message.success("删除成功");
        this.query();
      } else {
        this.$message.error(res.msg);
      }
    },
    filterOption(inputValue, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) >= 0
      );
    }
  },
  components: {
    PageView
  }
};
</script>

<style lang="less" scoped>
.top5 {
  margin-bottom: 8px;
}
</style>
